import React from 'react';
import { projectTypeClassName } from '../../../../../Utilities/helpers';
import "./ProjectCard.css";
import { Tooltip } from 'antd';

const ProjectCard = ({
    projectData,
    handleType,
    typeOfScreen,
    handleScreen,
    projectStatus,
    imgsrc
}) => {

    console.log("project details", handleType);
    console.log("screentypeeeeeee",typeOfScreen)
    console.log(projectData,"statusssssssssss")

    const getColor=(color)=>{

        switch(color){

            case "Yet To Start":

                return "#FF4D4D";
            
            case "In Progress":

                return "#9c1ab2";

            case "Completed":

                return "#52D69A";
                
            case "Yet To Allocate":
                
                return "#90A4AE";

        }

    }
    return (
        <div className='cardList' onClick={() => { if (handleType !== undefined){ handleType(projectData, "ProjectDetails") }}}>
            <img src={projectData?.imageUrls?.find((value) => value != null)} className='smlDummyImage' />
            <div className='projectCardInfo'>
                
                <h4 style={{fontWeight:500,fontSize:19.5}}>{projectData?.projectName}</h4>
                <Tooltip placement="topLeft" title={projectData?.projectLocation}>
                <div className='projectLocation projectCardOverflow'>{projectData?.projectLocation}</div>
                </Tooltip>
                <div className='projectLocation'>{projectData?.projectCity}</div>
                <div className='projectPincode'>PinCode: {projectData?.projectPincode}</div>
                <div style={{display:"flex",justifyContent:"space-between"}}>
                <span
                    className={`projectType ${projectTypeClassName(projectData?.projectType, projectData?.noOfFloors)}`}
                >
                    {projectData?.projectType !== "Others" ?
                        projectData?.projectType :
                        projectData?.projectType + " " + projectData?.noOfFloors
                    }
                </span>
               
              {(typeOfScreen==="PC" || typeOfScreen=== "PM" || typeOfScreen=== "PMO")  && 
              <span  style={{borderRadius:"7px",
                padding:"2px 7px",    
                color: getColor(projectData?.projectState),

              border: `1px solid ${getColor(projectData?.projectState)}`,
              letterSpacing:"0.5px",fontWeight:500,height:"25px"
                   
                }}>
                 { projectData ?.projectState}
                </span>}

                </div>
   
               

              
               
            </div>
            {(["PMO", "PM", "PC"].includes(typeOfScreen)) ?
                <div className='projectAllocatedBtn'>
                    <button
                        type="button"
                        className={`btn ${projectData?.projectState === "Yet To Allocate" ? "saveBtn" : "saveBtnclose"}`}
                        onClick={projectData?.projectState === "Yet To Allocate" ? () => handleScreen(projectData) : null}
                    >
                        {projectData?.projectState === "Yet To Allocate" ? "Allocate" : "Allocated"}
                    </button>

                </div> :
                <div className='projectTotalCost'>
                    <label className='col'>Total Cost</label>
                    <p className='col'><i class="fa fa-inr"></i>{projectData?.totalCost}</p>
                </div>
            }
        </div>
    )
}

export default ProjectCard