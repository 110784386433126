import instanceAll from './CommonAxiosInstance';

export const getRawLeads = async (body) => {
    console.log("inside the get raw leads", body);
    try {
        const response = await instanceAll.post("/get_leads_list", body);
        console.log("response :::", response.data);
        if (response.status === 200) {
            return ({
                status: "success",
                rawleads: response.data.leads,
                count: response.data.count
            })
        }

    }
    catch (error) {
        console.log("inside the catch block of raw leads");
        return ({
            status: "failed",

        })
    }
}


export const allocateService = async (body) => {
    console.log("inside the allocate leads");
    try {

        const response = await instanceAll.post("/allocate-customer", body);
        console.log('response', response);

        if (response?.status === 200 && response?.data?.allocations?.length > 0) {
            const allocatedPersons = response.data.allocations
                .filter(allocation => allocation.isCustomerallocated)
                .map(allocation => ({
                    allocatedPerson: allocation.allocatedPerson,
                    leadId: allocation.leadId
                }));

            if (allocatedPersons.length > 0) {
                return {
                    status: "success",
                    allocatedPersons
                };
            } else {
                return {
                    status: "failed",
                    message: "No customers allocated"
                };
            }
        } else {
            return {
                status: "failed",
                message: "No allocations found or invalid response"
            };
        }
    }

    
    catch (error) {
        console.log("inside the catch block of allocate lead");
        return ({
            status: "failed",

        })
    }
}

export const allocateServiceToSc = async (body) => {
    console.log("inside the allocate leads");
    
    try {
      // Making the POST request
      const response = await instanceAll.post("/allocate-customer-to-sc", body);
      console.log('API Response:', response);
  
      // Validating the response
      if (response?.status === 200 && response?.data?.allocatedLeads) {
        const allocatedData = response.data.allocatedData;
  
        if (allocatedData?.length > 0) {
          return {
            status: "success",
            allocatedPersons: allocatedData,
          };
        } else {
          return {
            status: "failed",
            message: "No customers allocated",
          };
        }
      } else {
        return {
          status: "failed",
          message: "No allocations found or invalid response",
        };
      }
    } catch (error) {
      console.error("Error in allocateServiceToSc:", error.message);
  
      return {
        status: "failed",
        message: error.message || "An error occurred while allocating leads",
      };
    }
  };

  export const allocateServiceToSe = async (body) => {
    console.log("inside the allocate leads");
    
    try {
      // Making the POST request
      const response = await instanceAll.post("/allocate-customer-to-se", body);
      console.log('API Response:', response);
  
      // Validating the response
      if (response?.status === 200 && response?.data?.allocatedLeads) {
        const allocatedData = response.data.allocatedData;
  
        if (allocatedData?.length > 0) {
          return {
            status: "success",
            allocatedPersons: allocatedData,
          };
        } else {
          return {
            status: "failed",
            message: "No customers allocated",
          };
        }
      } else {
        return {
          status: "failed",
          message: "No allocations found or invalid response",
        };
      }
    } catch (error) {
      console.error("Error in allocateServiceToSc:", error.message);
  
      return {
        status: "failed",
        message: error.message || "An error occurred while allocating leads",
      };
    }
  };
  
export const junkService = async (body) => {
    console.log('inside the junk service ', body);
    try {
        const response = await instanceAll.post("/junk-customer", body);
        console.log("junk response", response);
        if (response?.status === 200) {
            return ({
                status: "success",

            })
        }
        else {
            return ({
                status: "failed"
            })
        }
    }
    catch (error) {
        console.log('inside the catch block of junk service raw leads');
        return ({
            status: "failed"
        })
    }
}

export const filterRawData = async (body) => {
    console.log("inside the filter raw data", body);
    try {
        const response = await instanceAll.post("/get-filter-rawleads", body);
        if (response.status === 200 && response?.data?.getfilterList) {
            return ({
                status: "success",
                data: response?.data.leads,
                count: response?.data.count
            })
        }
        else {
            return ({
                status: "failed",

            })
        }
    }
    catch (error) {
        return ({
            status: "failed"
        })
    }

}

export const addRawLead = async (body) => {
    console.log("add raw leads", body);
    try {
        const response = await instanceAll.post("/add-raw-lead", body);
        if (response.status === 200 && response.data.isRawLeadAdded) {
            return (
                {
                    status: "success",

                }
            )
        }
        else {
            return (
                {
                    status: "failed"
                }
            )
        }

    }
    catch (error) {
        return (
            {
                status: "failed",
                message: error
            }
        )
    }
}
export const rawLeadsAdvancedFilter = async (body) => {
    console.log("inside the filter raw data", body);
    try {
        const response = await instanceAll.post("/rawLeads-advanced-date-filter", body);
        console.log("response---", response);
        if (response?.data?.status === 200 && response?.data?.getRawLeadsFilterList) {
            return ({
                status: "success",
                data: response?.data.leadsData,
                count: response?.data.count,
                message: response?.data.message
            })
        }
        else {
            return ({
                status: "failed",

            })
        }
    }
    catch (error) {
        return ({
            status: "failed"
        })
    }

}

export const rawLeadsSearch = async (body) => {
    try {
        const response = await instanceAll.post("/get-rawLeads-by-search", body);
        console.log("rawLeadsSearch:", response?.data);
        if (response && response.status === 200) {
            return ({
                status: "success",
                data: response?.data,
                count: response?.data.count

            })
            
        } else {
            return ({
                status: "failed",
                data: "No data found"
            })
        }

    } catch (error) {
        return ({
            message: error
        })
    }
}

export const rawLeadsSearchBySo = async (body) => {
    try {
        const response = await instanceAll.post("/get-rawLeads-by-search-by-so", body);
        console.log("rawLeadsSearchSo:", response?.data);
        if (response && response.status === 200) {
            return ({
                status: "success",
                data: response?.data,
                count: response?.data.count

            })
            
        } else {
            return ({
                status: "failed",
                data: "No data found"
            })
        }

    } catch (error) {
        return ({
            message: error
        })
    }
}
export const uploadExcelDataService = async (body) => {
    try {
        const response = await instanceAll.post("/upload-excel-data", body);
        if (response.status === 200 && response.data.isUploaded) {
            return (
                {
                    status: 'success',
                    message: "Successfully Added the Excel Data"
                }
            )
        }
        else {
            return (
                {
                    status: "failed",
                    message: "something went wrong"
                }
            )

        }

    } catch (error) {
        return ({
            status: "failed",
            message: "something went wrong"

        })

    }
}

export const allocationCommitService = async (body) => {
    try {
        const response = await instanceAll.post("/allocation-commit", body);
        if (response.status === 200 && response.data.isAllocationCommitted) {
            return (
                {
                    status: "success",
                    message: "Successfully Allocated"
                }
            )
        }
        else {
            return (
                {
                    status: "failed",
                    message: "No Data Found"
                }
            )
        }

    } catch (error) {
        return (
            {
                status: "failed",
                message: error
            }
        )
    }
}

export const getUserCities = async (body) => {
    try {
        const response = await instanceAll.post("/get-user-cities", body);
        console.log(response,"response")
        if (response) {
            return (
                {
                    status: "success",
                    message: "Successfully retrived the cities",
                    city:response.data.city
                }
            )
        }
        else {
            return (
                {
                    status: "failed",
                    message: "No Data Found"
                }
            )
        }

    } catch (error) {
        return (
            {
                status: "failed",
                message: error
            }
        )
    }
}

export const callLeads = async (body) => {
    try {
        const res = await instanceAll.post("/call-raw-lead", body);
        console.log("Called the lead: ", res);
        
        if (res.status === 200 && res.data) {
            return (
                {
                    status: "success",
                    message: "Called Lead Successfully",
                    data: res.data
                }
            )
        } else {
            return (
                {
                    status: "failed",
                    message: "Failed to Call Lead"
                }
            )
        }
    } catch (error) {
        return (
            {
                status: "failed",
                message: error
            }
        )
    }
}



export const deAllocationSalesOfficeService=async(body)=>{
    try{
    const response=await instanceAll.post("/deallocation-so",body);
    console.log(response,"responseeeeeeeeee")

    if(response?.data?.leadDeAllocated && response?.data?.status===200){
        return(
            {
                status:"Success",
                message:"Successfully Deallocated"
            }
        )
    }
    else {
        return (
            {
                status: "failed",
                message: "No Data Found"
            }
        )
    }



    }
    catch(error){
        return(
            {
                status:"failed",
                message:error
            }
        )
    }
}




export const deAllocationSalesCoordinatorService=async(body)=>{
    try{
    const response=await instanceAll.post("/deallocation-sc",body);
    console.log(response,"responseeeeeeeeee")

    if(response?.data?.leadDeAllocated && response?.data?.status===200){
        return(
            {
                status:"Success",
                message:"Successfully Deallocated"
            }
        )
    }
    else {
        return (
            {
                status: "failed",
                message: "No Data Found"
            }
        )
    }



    }
    catch(error){
        return(
            {
                status:"failed",
                message:error
            }
        )
    }
}
export const getAgentPhone = async (body) => {
    try {
        const response = await instanceAll.post("/get-user-cities", body);
        console.log(response,"response")
        if (response) {
            return (
                {
                    status: "success",
                    message: "Successfully retrived the cities",
                    phone:response.data._mobile
                }
            )
        }
        else {
            return (
                {
                    status: "failed",
                    message: "No Data Found"
                }
            )
        }

    } catch (error) {
        return (
            {
                status: "failed",
                message: error
            }
        )
    }
}


export const getCallRecordings = async (body) => {
    try {
        const response = await instanceAll.post("/api/recordings", body);
        console.log(response,"response")
        if (response) {
            return (
                {
                    status: "success",
                    message: "Successfully retrived the recordings",
                    data :response.data
                }
            )
        }
        else {
            return (
                {
                    status: "failed",
                    message: "No call recordings Found"
                }
            )
        }

    } catch (error) {
        return (
            {
                status: "failed",
                message: error
            }
        )
    }
}


export const fetchInProgressCalls = async () => {
    try {
        const response = await instanceAll.get('/api/pending-calls');
        console.log("response from API: " + JSON.stringify(response));
        
        if (response.data.status === 200) {
            return (
                {
                    status: "success",
                    message: "Sucessfully retrieved the in-progress calls.",
                    data: response.inProgressCalls
                }
            );
            
            
        }
    } catch (error) {
        console.error("Error fetching in-progress calls: ", error.message);
    }
};

export const getCallStatus = async (sid) => {
    try {
        const response = await instanceAll.get('/api/pending-calls');
        console.log("Full response from API: ", response);
        
        const inProgressCalls = response.data?.inProgressCalls; // Safely access nested fields
        console.log("In-progress calls: ", inProgressCalls);
        
        const match = inProgressCalls.includes(sid);
        
        if (response.data?.status === 200) {
            return {
                status: "success",
                message: "Successfully retrieved the in-progress calls.",
                data: inProgressCalls,
                result: match,
            };
        } else {
            return {
                status: "error",
                message: "Response status not 200.",
                result: false,
            };
        }
    } catch (error) {
        console.error("Error fetching in-progress calls: ", error.message);
        return {
            status: "error",
            message: "Failed to fetch in-progress calls.",
            result: false,
        };
    }
};



export const getSCLeadsService=async(body)=>{
    try{
    const response=await instanceAll.post("/get-sc-leads",body);
    console.log(response,"responseeeeeeeeee")

    if(response){
        return(
            {
                status:"Success",
                data:response.data.data,
                message:"Successfully get the Leads"
            }
        )
    }
    else {
        return (
            {
                status: "failed",
                message: "No Data Found"
            }
        )
    }



    }
    catch(error){
        return(
            {
                status:"failed",
                message:error
            }
        )
    }
}

export const reallocateSCService=async(body)=>{
    try{
    const response=await instanceAll.post("/reallocate-sc",body);
    console.log(response,"responseeeeeeeeee222222")

    if(response){
        return(
            {
                status:"Success",
                data:response.data.data,
                message:"Successfully get the Leads"
            }
        )
    }
    else {
        return (
            {
                status: "failed",
                message: "No Data Found"
            }
        )
    }



    }
    catch(error){
        return(
            {
                status:"failed",
                message:error
            }
        )
    }
}

export const reallocateSEService=async(body)=>{
    try{
    const response=await instanceAll.post("/reallocate-se",body);
    console.log(response,"responseeeeeeeeee222222")

    if(response){
        return(
            {
                status:"Success",
                data:response.data.data,
                message:"Successfully get the Leads"
            }
        )
    }
    else {
        return (
            {
                status: "failed",
                message: "No Data Found"
            }
        )
    }



    }
    catch(error){
        return(
            {
                status:"failed",
                message:error
            }
        )
    }
}

export const getSCLogsService=async(body)=>{
    try{
    const response=await instanceAll.post("/get-sc-logs",body);
    console.log(response,"responseeeeeeeeee3333")

    if(response){
        return(
            {
                status:"Success",
                data:response.data.data,
                message:"Successfully get the Leads"
            }
        )
    }
    else {
        return (
            {
                status: "failed",
                message: "No Data Found"
            }
        )
    }



    }
    catch(error){
        return(
            {
                status:"failed",
                message:error
            }
        )
    }
}

export const getSELogsService=async(body)=>{
    try{
    const response=await instanceAll.post("/get-se-logs",body);
    console.log(response,"responseeeeeeeeee3333")

    if(response){
        return(
            {
                status:"Success",
                data:response.data.data,
                message:"Successfully get the Leads"
            }
        )
    }
    else {
        return (
            {
                status: "failed",
                message: "No Data Found"
            }
        )
    }



    }
    catch(error){
        return(
            {
                status:"failed",
                message:error
            }
        )
    }
}