import axios from 'axios';
import instanceAll from './CommonAxiosInstance';


export const  ResetPasswordService=async(body)=>{
    try{
        console.log(body,"body in reset password serviceeeeeeee")
 
        const response = await instanceAll.post("/reset-password-input", body);
        
        console.log(response,"response in instance all file")
        if(response && response?.status===200){
            
            return{
                status:"success",
                data:response.data,
            }
          
        }
        else {
            console.log("Something Went Wrong");
        }
    }
  
    catch(error){
        return (
            {
                status: "Failed",
                message: error
            }
        )
    }


}