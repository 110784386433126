import { Select, Modal } from "antd"
import React, { useEffect, useState } from "react"
import "./GenericLeadDetails.css"
import SaveAndCancel from "../../MyLeads/GenericTask/SaveAndCancel/SaveAndCancel"
import GenericTask from "../../MyLeads/GenericTask/GenericTask"
import { toast } from "react-toastify"
// import Editor from 'react-simple-wysiwyg';
import { DefaultEditor } from "react-simple-wysiwyg"
import { getLeadDataById, getMyLeadActivityList, updateLeadStatusAndTask } from "../../../../../Service/MyLeadsService"
import { logoHandler, servicesConversion } from "../../../../../Utilities/helpers"
import { useHistory, useLocation } from "react-router-dom"
import moment from "moment"

const servicesOptions = [
    { label: "Residential Constrution", value: "Residential Constrution" },
    { label: "Commercial Construction", value: "Commercial Construction" },
    { label: "Architecture Services", value: "Architecture Services" },
    { label: "Interior & smart Homes", value: "Interior & smart Homes" },
    // { label: "Project Management", value: "Project Management" }
]
const buttonList = ["Cold", "Hot", "Future", "Discarded", "Lost", "Won"]

const GenericLeadDetails = ({ leadId, activityTypeName, setActivityTypeName }) => {
    console.log("leadId", leadId)
    const [leadModalVisible, setLeadModalVisible] = useState(false)

    const [leadStatus, setLeadStatus] = useState({
        leadStatus: "",
        remarks: "",
        leadActivities: [],
        taskName: "",
        taskDescription: "",
        meetingAttendees: [],
        callScheduleDate: null,
        meetingScheduleDate: null,
        callScheduleTime: null,
        meetingScheduleTime: null,
        taskStartDate: null,
        taskEndDate: null,
        uploadedFiles:[]
    })
    const [activityId, setActivityId] = useState("")
    const [outcome, setOutcome] = useState("")
    const [leadData, setLeadData] = useState({
        leadName: "",
        leadServices: "",
        leadCity: "",
        leadMobile: "",
        leadStatus: "",
        remarks: "",
        leadEmail: "",
        leadMessage: "",
        source: ""
    })
    const history = useHistory()
    const location = useLocation()
    const tabName = location && location.pathname.split("/").pop()
    console.log("location", location, tabName)

    /*  change the all states to initial values*/
    const handleReset = () => {
        setLeadStatus({
            leadStatus: leadData?.leadStatus,
            remarks: leadData?.remarks,
            leadActivities: [],
            taskName: "",
            taskDescription: "",
            //  callAttendees:[],
            meetingAttendees: [],
            callScheduleDate: null,
            meetingScheduleDate: null,
            callScheduleTime: null,
            meetingScheduleTime: null,
            taskStartDate: null,
            taskEndDate: null
        })
        setOutcome("")
        // setButtonActive("");
        // setTaskAdding(false);
        // setActivityId("");
    }

    const closeModal = () => {
        setLeadModalVisible(false)
        // handleReset()//commented for avoiding the reseting the default data showing
    }
    /* handle cancel button operation method*/
    const handleCancel = (type) => {
        closeModal()
    }

    /* handle save and save close operation method*/
//     const handleSave = async (type) => {
//        console.log(leadStatus,"setLeadStatus")

//         let data
//         if (activityId) {
//             data = {
//                 userId: window.localStorage.getItem("user_Id"),
//                 leadId: leadData?.leadId,
//                 ...leadStatus,
//                 activityId
//             }
//         } else {
//             data = {
//                 userId: window.localStorage.getItem("user_Id"),
//                 leadId: leadData?.leadId,
//                 ...leadStatus
//             }
//         }
// console.log(data,"Add lead Task")
//         const response = await updateLeadStatusAndTask(data)
//         console.log(response)
//         if (response?.status === "success") {
//             setActivityId(response?.activityId)
//             // handleMyLeads();
//             getLeadData({ leadId: leadId })
//             toast.success(response.message)
//             if (type === "save&close") {
//                 handleCancel()
//             }
//         } else {
//             toast.error("Failed to add the lead status")
//         }
//     }

const handleSave = async (type) => {
    console.log(leadStatus, "setLeadStatus");

    const formData = new FormData();

    formData.append("userId", window.localStorage.getItem("user_Id"));
    formData.append("leadId", leadData?.leadId);
    formData.append("leadStatus", leadStatus.leadStatus);
    formData.append("remarks", leadStatus.remarks);
    formData.append("taskName", leadStatus.taskName);
    formData.append("taskDescription", leadStatus.taskDescription);
    formData.append("callScheduleDate", leadStatus.callScheduleDate);
    formData.append("meetingScheduleDate", leadStatus.meetingScheduleDate);
    formData.append("callScheduleTime", leadStatus.callScheduleTime);
    formData.append("meetingScheduleTime", leadStatus.meetingScheduleTime);
    formData.append("taskStartDate", leadStatus.taskStartDate);
    formData.append("taskEndDate", leadStatus.taskEndDate);
    formData.append("leadActivities", JSON.stringify(leadStatus.leadActivities));
    formData.append("meetingAttendees",JSON.stringify(leadStatus.meetingAttendees))

    leadStatus.uploadedFiles.forEach(file => {
        if (file instanceof File) {
            formData.append("Files", file);
        } else if (file.imageLink) {
            formData.append("Files", file.imageLink);
            
        }
    });

    if (activityId) {
        formData.append("activityId", activityId);
    }

    console.log(formData, "Add lead Task");

    const response = await updateLeadStatusAndTask(formData);
    console.log(response);

    if (response?.status === "success") {
        setActivityId(response?.activityId);
        getLeadData({ leadId: leadData?.leadId });
        toast.success(response.message);

        if (type === "save&close") {
            handleCancel();
        }
    } else {
        toast.error("Failed to add the lead status");
    }
};




    /* handle modal */
    const handleModal = () => {
        setLeadModalVisible(true)
    }

    const handleLeadStatus = (status) => {
        console.log("status", status)
        if (status !== "Won") {
            // setButtonActive(status);
            setLeadStatus((values) => ({ ...values, leadStatus: status }))
        } else {
            history.push("/landing/salesexecutive/customers", { type: "newlead", leadDetails: leadData })
        }
    }

    /* validating the status and remarks field*/
    const fieldsValidation = () => {
        if (leadStatus?.leadStatus && leadStatus?.remarks) {
            return true
        } else {
            return false
        }
    }
    const typeHandler = (type) => {
        console.log("type", type)
        if (type === "addtask" && fieldsValidation()) {
            // setTaskAdding(true);
            setLeadModalVisible(true)
        } else if ((type === "save" || type === "save&close") && fieldsValidation()) {
            console.log("strippedHtml--save--", strippedHtml)
            setLeadStatus((values) => ({ ...values, remarks: strippedHtml }))
            handleSave(type)
        } else {
            toast.warning("please choose status and add remarks")
        }
    }

    const servicetype = (service) => {
        try {
            JSON.parse(JSON.parse(JSON.stringify(service)))
            return "object"
        } catch (error) {
            return "string"
        }
    }
    /*handle rich text editor changes */
    const onChangeEditor = (val) => {
        setLeadStatus((values) => ({ ...values, remarks: val }))
    }

    /* converting HTML format into normal text */
    let strippedHtml = leadStatus?.remarks?.replace(/<br>|&nbsp;/g, "")
    console.log("after-brr--", strippedHtml)
    strippedHtml = strippedHtml?.replace(/<div>/g, "\n")
    console.log("strippedHtml--", strippedHtml)

    const getLeadData = async () => {
        try {
            const response = await getLeadDataById({ leadId: leadId })
            console.log("respone", response)
            if (response.status === "success") {
                setLeadStatus((values) => ({
                    ...values,
                    leadStatus: response.leadData?.leadStatus,
                    remarks: response.leadData?.remarks
                }))
                setLeadData({
                    leadId: response.leadData.leadId,
                    leadName: response.leadData.leadName,
                    lastName: response.leadData?.lastName,
                    leadServices: response.leadData.leadServices,
                    leadCity: response.leadData.leadCity,
                    leadMobile: response.leadData.leadMobile,
                    leadStatus: response.leadData.leadStatus,
                    remarks: response.leadData.remarks,
                    leadEmail: response.leadData.leadEmail,
                    leadMessage: response.leadData.leadMessage,
                    source: response.leadData.source,
                    leadcreatedDateTime: response.leadData.leadcreatedDateTime
                })
            } else {
                toast.error(" Something went wrong")
            }
        } catch (error) {
            console.log("error", error)
            toast.error(" Something went wrong")
        }
    }

    const [taskList, setTaskList] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [numberPerPage, setNumberPerPage] = useState(8)
    const [count, setCount] = useState(0)
    const getMyLeadActivityService = async (dateRange, activity, pageNo) => {
        try {
            let body
            if (pageNo) {
                setCurrentPage(1)
                body = {
                    leadId: leadId,
                    activityType: activityTypeName,
                    dateFrom: dateRange?.startDate ? moment(dateRange?.startDate).format("YYYY-MM-DD") : null,
                    dateTo: dateRange.endDate ? moment(dateRange?.endDate).format("YYYY-MM-DD") : null,
                    pageIndex: pageNo,
                    numberPerPage: numberPerPage
                }
            } else {
                body = {
                    leadId: leadId,
                    activityType: activityTypeName,
                    dateFrom: dateRange?.startDate ? moment(dateRange?.startDate).format("YYYY-MM-DD") : null,
                    dateTo: dateRange?.endDate ? moment(dateRange?.endDate).format("YYYY-MM-DD") : null,
                    pageIndex: currentPage,
                    numberPerPage: numberPerPage
                }
            }
            const response = await getMyLeadActivityList(body)
            console.log("response====>", response)
            if (response.status === "success") {
                const data = response.data
                setLeadStatus((prev) => ({
                    ...prev,
                    taskName: data.length > 0 && data[0].activityName,
                    taskDescription: data.length > 0 && data[0]?.activityDescription,
                    taskStartDate: data.length > 0 && data[0]?.activityStartDate,
                    taskEndDate: data.length > 0 && data[0]?.activityDueDate,
                    callScheduleTime: data.length > 0 && data[0]?.appointmentTime, 
                    callScheduleDate: data.length > 0 && data[0]?.appointmentDate,
                    meetingAttendees: data.length > 0 && data[0]?.meetingAttendees === null ?[]:data[0]?.meetingAttendees,
                    meetingScheduleDate: data.length > 0 && data[0]?.appointmentDate,
                    meetingScheduleTime: data.length > 0 && data[0]?.appointmentTime,
                    uploadedFiles: data.length > 0 && data[0]?.uploadedFiles ? data[0].uploadedFiles : []
                }))
                setActivityId(data[0]?.activityId)
                setOutcome(activityTypeName)
                setTaskList(response.data)
                setCount(response.count)
            } else {
                setLeadStatus(prev=>({
                    ...prev,
                    taskName: "",
                    taskDescription: "",
                    //  callAttendees:[],
                    meetingAttendees: [],
                    callScheduleDate: null,
                    meetingScheduleDate: null,
                    callScheduleTime: null,
                    meetingScheduleTime: null,
                    taskStartDate: null,
                    taskEndDate: null,
                    uploadedFiles: []
                }))
                setTaskList([])
                setCount(0)
                // toast.error('Something went wrong');
            }
        } catch (error) {
            setTaskList([])
            setCount(0)
            toast.error("Something went wrong")
        }
    }
    useEffect(() => {
        getLeadData()
    }, [])
    useEffect(() => {
        getMyLeadActivityService()
    }, [activityTypeName])

    console.log("lead status", leadStatus)
    return (
        <div className="contentBody detail">
            <div>
                <div className="row">
                    <div className="col-md-8" style={{ margin: "auto" }}>
                        <div className="whCard whCardMargin">
                            <div className="cardHeader">
                                <span>Lead Details</span>
                                <div className="d-flex customer-details-btn">
                                    <img src={logoHandler(leadData?.source)} alt="google-logo" height="30" />
                                </div>
                            </div>
                            <div className="cardBody">
                                <div className="row">
                                    <div className="col">
                                        <div>
                                            <label>First Name *</label>
                                        </div>
                                        <div>
                                            <input
                                                type="text"
                                                name="firstName"
                                                value={leadData?.leadName.split(" ")[0]}
                                                disabled
                                                placeholder="Enter First Name"
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div>
                                            <label>Last Name</label>
                                        </div>
                                        <div>
                                            <input
                                                type="text"
                                                name="lastName"
                                                value={leadData?.lastName}
                                                disabled
                                                placeholder="Enter Last Name"
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div>
                                            <label>Email *</label>
                                        </div>
                                        <div>
                                            <input
                                                type="email"
                                                name="email"
                                                value={leadData?.leadEmail}
                                                disabled
                                                className="form-control"
                                                placeholder="Enter Email Id"
                                            />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div>
                                            <label>Mobile *</label>
                                        </div>
                                        <div>
                                            <input
                                                type="text"
                                                name="phone"
                                                value={leadData?.leadMobile}
                                                disabled
                                                className="form-control"
                                                placeholder="Enter Mobile Number"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div>
                                            <label>City *</label>
                                        </div>
                                        <div>
                                            <select
                                                value={leadData?.leadCity}
                                                className="form-select"
                                                disabled
                                                name="city"
                                            >
                                                <option hidden>Select Your City</option>
                                                <option>Hyderabad</option>
                                                <option>Chennai</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div>
                                            <label>Services *</label>
                                        </div>
                                        <div>
                                            <Select
                                                options={servicesOptions}
                                                style={{ width: "100%" }}
                                                className="form-control"
                                                mode="tags"
                                                value={servicesConversion(leadData?.leadServices)}
                                                placeholder={"select Services"}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="customer-details-form-group">
                                            <div>
                                                <label>Inquiry Message *</label>
                                            </div>
                                            <div>
                                                <textarea
                                                    name="residentialAddress"
                                                    value={leadData?.leadMessage}
                                                    disabled
                                                    className=""
                                                    rows="3"
                                                    placeholder="Enter Inquiry Message"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div>
                                    <label>Lead Status</label>
                                    <div className="genericbtns mb-3">
                                        {buttonList.map((name, index) => {
                                            return (
                                                <button
                                                    type="button"
                                                    className={`btn btn-sm ${name}  ${
                                                        leadStatus?.leadStatus === name ? `${name}-active` : ""
                                                    } `}
                                                    onClick={() => handleLeadStatus(name)}
                                                    key={index}
                                                >
                                                    {name === "Discarded" ? "Discard" : name}
                                                </button>
                                            )
                                        })}
                                    </div>
                                    <div>
                                        <label>Sales Executive Comments</label>
                                        <DefaultEditor
                                            value={leadStatus?.remarks?.replace(/(<div>)/gi, "\n")}
                                            onChange={(e) => onChangeEditor(e.target.value)}
                                        />
                                        {console.log("leadStatus?.remarks", leadStatus?.remarks)}
                                    </div>
                                </div>
                                <div className="genericfooter">
                                    <SaveAndCancel
                                        handleSave={typeHandler}
                                        handleCancel={handleCancel}
                                        isTaskAdding={"genericdetails"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                visible={leadModalVisible}
                onOk={closeModal}
                onCancel={closeModal}
                footer={null}
                className="customModal"
                title={"Add Activity"}
            >
                <GenericTask
                    taskList={taskList}
                    selectedUser={leadData}
                    handleSave={handleSave}
                    handleCancel={handleCancel}
                    outcome={outcome}
                    setOutcome={setOutcome}
                    leadStatus={leadStatus}
                    setLeadStatus={setLeadStatus}
                    setActivityTypeName={setActivityTypeName}
                />
            </Modal>
        </div>
    )
}

export default GenericLeadDetails
