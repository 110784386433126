import React from 'react';
import ReactDOM from 'react-dom';
import 'rc-pagination/assets/index.css';
// import 'antd/dist/antd.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import App from './App';
// import {createStore} from 'redux';
import { Provider } from 'react-redux';
// import reducer from './Store/Reducer';
import store from './Store/Store';
// import themes from 'devextreme/ui/themes';
// const store = createStore(reducer);


ReactDOM.render(
  <Provider store={store}>
      <App />
  </Provider>,
  document.getElementById('root')
);



