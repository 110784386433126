import { lazy } from "react"

// Partner Executive SCreens:
const Dashboard = lazy(() => import('./Admin/partnerExecutive/Dashboard/Dashboard'));
const TurnkeyPartner = lazy(() => import("./Admin/partnerExecutive/TurnkeyPartners/TurnkeyPartner"));
const SkilledWorkers = lazy(() => import("./Admin/partnerExecutive/SkilledWorkers/SkilledWorkers"));
const ArchitechPartners = lazy(() => import("./Admin/partnerExecutive/ArchitechPartners/ArchitechPartners"));
const InteriorPartners = lazy(() => import("./Admin/partnerExecutive/InteriorPartners/InteriorPartners"));
// const SetUpInterviews = lazy(() => import("./Admin/partnerExecutive/SetUpInterviews/SetUpInterviews"));
const MyTrips = lazy(() => import("./Admin/partnerExecutive/MyTrips/MyTrips"));

// Admin Screens
const Wehouse = lazy(() => import("./Admin/WeHouseUsers/Wehouse"))
const AddWeHouseUsers = lazy(() => import("./Admin/WeHouseUsers/AddWehouseUser/AddWeHouseUsers"));
const WehouseUsersList = lazy(() => import("./Admin/WeHouseUsers/WehouseUsersLIst/WeHouseUsers"));

// Sales Executive Screens:
const SeDashboard = lazy(() => import("./Admin/salesExecutive/SeDashboard/Dashboard"));
const Customers = lazy(() => import("./Admin/salesExecutive/SeCustomers/Customers"));
const Projects = lazy(() => import("./Admin/salesExecutive/SeProjects/Projects"));
const Leads = lazy(() => import("./Admin/salesExecutive/MyLeads/Leads"));
const Tasks = lazy(() => import("./Admin/salesExecutive/MyTask/Task"));
const SalesExecutiveCalls = lazy(() => import("./Admin/salesExecutive/Mycalls/MyCalls"));
const SalesExecutiveAppointments = lazy(() => import("./Admin/salesExecutive/MyAppointments/Appointments"));
const SalesExecutiveGenericActivites = lazy(() => import("./Admin/salesExecutive/GenericActivity/GenericActivity"));

//  Partner Manager Screens:
const PmDashboard = lazy(() => import("./Admin/partnerManager/Dashboard/Dashboard"));
const allTKPUsers = lazy(() => import("./Admin/partnerManager/AllTKPUsers/allTKPUsers"));
const TkpPenndingApprovals = lazy(() => import("./Admin/partnerManager/TKPPendingApprovals/TkpApprovals"));
const TkpOnboardingProcess = lazy(() => import("./Admin/partnerManager/TKPPendingApprovals/TkpOnboaring/TkpOnboardingProcess"));
const AllSkilledWorkersList = lazy(() => import("./Admin/partnerManager/AllSkilledWorkers/allSkilledWorkers"))
const SkilledWorkersPending = lazy(() => import("./Admin/partnerManager/SkilledWorkerPendingApproals/skilledWorkerPending"));
const SkilledWorkersOnboardingProcess = lazy(() => import("./Admin/partnerManager/SkilledWorkerPendingApproals/SwOnboardingProcess/swOnboardingProcess"));
const AllInteriorPartnersList = lazy(() => import("./Admin/partnerManager/AllInteriorPartners/allInteriorPartners"));
const InteriorPartnersPending = lazy(() => import("./Admin/partnerManager/InteriorPendingApprovals/interiorPartnersPending"))
const AllArchitectPartnersList = lazy(() => import("./Admin/partnerManager/AllArchitectPartners/allArchitectPartners"));
const ArchitectPartnersPending = lazy(() => import("./Admin/partnerManager/ArchitectPendingApprovals/architectpartnersPending"));
// const MyCalender = lazy(() => import("./Admin/partnerManager/MyCalender"));
const TkpAppoinment = lazy(() => import("./Admin/partnerManager/TkpAppointmentsCalender/tkpAppoinmentsDetails"));
const ArchitectPartnerOnboardingProcess = lazy(()=> import("./Admin/partnerManager/ArchitectPendingApprovals/ApOnboardingProccess/apOnboardingProccess"));
const InteriorPartnersOnboardingProcess = lazy(()=> import('./Admin/partnerManager/InteriorPendingApprovals/IponboardingProcess/ipOnboardingProcess'));



//  sales coordinator
const SalesCoordinatorDashboard = lazy(() => import("./SalesCoordinator/Dashboard/Dashboard"));
const RawLead = lazy(() => import("./SalesCoordinator/RawLeads/RawLeads"));
const Projects1=lazy(()=>import("./SalesCoordinator/SeProjects/Projects"))
const Customers1=lazy(()=>import("./SalesCoordinator/Customers"))


// 
const TkpDashboard = lazy(() => import("./Admin/tkpMenu/Dashboard"));
const MaterialOrder = lazy(() => import("./Admin/tkpMenu/MaterialOrder"));
const RequestSkilledWorkers = lazy(() => import("./Admin/tkpMenu/RequestSkilledWorkers"));
const Users = lazy(() => import('./Admin/users/Users'));
const Roles = lazy(() => import('./Admin/roles/Roles'));

/* Project Management Officer */

const PMODashboard = lazy(() => import("./PMO/PMODashboard/PMODashboard"));
const PMOProjects = lazy(() => import("./PMO/PMOProjects/PMOProjects"));
// const PMOCustomers = lazy(() => import("./PMO/PMOCustomers/PMOCustomers"));

/* Project Coordinator  */

const PCDashboard = lazy(() => import("./PC/PCDashboard/PCDashboard"));
const PCProjects = lazy(() => import("./PC/PCProjects/PCProjects"));

/* No Data Found */
const ScreenDesign = lazy(() => import("../Components/ScreenDesign/ScreenDesign"));

/* customer screens */
const customerDashboard = lazy(() => import("./Customer/CustomerProjects/customerProjects"));
const BuildingProgress = lazy(()=> import("./Customer/BuildingProgress/buildingProgress"));
const Drawings = lazy(()=>import("./Customer/Drawings/Drawings"))
const PaymentProcess = lazy(()=>import("./Customer/PaymentProcess/paymentProcess"));

/* Project Manager screens */

const PMDashboard = lazy(() => import("./PM/PMDashboard/PMDashboard"));
const PMProjects = lazy(() => import("./PM/PMProjects/PMProjects"));
// const PMCustomers = lazy(() => import("./PM/PMCustomers/PMCustomers"));
// Sales OFfice Screens
const SODashboard = lazy(() => import("./SalesOffice/Dashboard/Dashboard"));
const SOLeads = lazy(() => import("./SalesOffice/RawLeads/RawLeads"));

export default {
    Dashboard,
    TurnkeyPartner,
    SkilledWorkers,
    ArchitechPartners,
    InteriorPartners,
    // SetUpInterviews,
    MyTrips,
    SeDashboard,
    Customers,
    Projects,
    Leads,
    PmDashboard,
    allTKPUsers,
    TkpPenndingApprovals,
    TkpOnboardingProcess,
    // SkilledWorkersApprovals,
    TkpDashboard,
    MaterialOrder,
    RequestSkilledWorkers,
    Users,
    Roles,
    Wehouse,
    WehouseUsersList,
    AddWeHouseUsers,
    SalesCoordinatorDashboard,
    RawLead,
    Tasks,
    SalesExecutiveAppointments,
    AllSkilledWorkersList,
    AllArchitectPartnersList,
    AllInteriorPartnersList,
    SalesExecutiveGenericActivites,
    SkilledWorkersPending,
    SkilledWorkersOnboardingProcess,
    ArchitectPartnersPending,
    ArchitectPartnerOnboardingProcess,
    InteriorPartnersPending,
    InteriorPartnersOnboardingProcess,
    // MyCalender,
    SalesExecutiveCalls,
    TkpAppoinment,
    /* Project Management Officer */
    PMODashboard,
    PMOProjects,
    // PMOCustomers,
    /* Project Coordinator */
    PCDashboard,
    PCProjects,
    /* Design in Progress */
    ScreenDesign,
    /* customer screens */
    customerDashboard,
    BuildingProgress,
    Drawings,
    PaymentProcess,
    /* project Mangager screens */
    PMDashboard,
    // PMCustomers,
    PMProjects,
    Projects1,

    // Sales OFfice Screens

    SODashboard,
    SOLeads
}