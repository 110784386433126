import instanceAll from './CommonAxiosInstance';

export const MyLeadsService = async (body) => {
    try {
        const response = await instanceAll.post("/get-my-leads", body);
        if (response?.status === 200) {
            return ({
                status: "success",
                MyLeads: response?.data.MyLeads,
                count: response.data.count
            })
        }
        else {
            return ({
                status: "failed",
                MyLeads: response?.data.MyLeads
            })
        }

    }
    catch (error) {
        return ({
            status: -1,
            message: error
        })
    }
}

export const updateLeadStatusAndTask = async (body) => {
    console.log("inside the  update lead status");
    try {
        const response = await instanceAll.post("/add-lead-status-task", body);
        console.log(response,"responsedata");
        if (response?.status === 200 && ((response?.data?.isUpdateStatus) || (response?.data?.isUpdateStatusAndTask))) {
            return ({
                status: "success",
                message: response.data.message,
                activityId: response.data?.activityId

            })
        }
        else {
            return ({
                status: "failed",

            })
        }

    }
    catch (error) {
        return ({
            status: "failed",
            message: error
        })
    }
}

// export const addLeadTask = async (body) => {
//     console.log("inside the add task ", body);
//     try {
//         const response = await instanceAll.post("/add-lead-task", body);
//         if (response.status === 200 && response.data.addTask) {
//             return ({
//                 status: "success"
//             })
//         }
//         else {
//             return ({
//                 status: "failed"
//             })
//         }

//     }
//     catch (error) {
//         return ({
//             status: "failed",
//             message: error
//         })
//     }
// }


export const filterLeadsByExecutive = async (body) => {
    console.log("inside the filte leads  by executive", body);
    try {
        const response = await instanceAll.post("/get-executive-filterLeads", body);
        if (response.status === 200) {
            return ({
                status: "success",
                MyLeads: response.data.MyLeads
            })
        }
        else {
            return ({
                status: "failed",
                MyLeads: []
            })
        }

    }
    catch (error) {
        return ({
            status: "failed",
            message: error
        })
    }
}


export const myLeadsTasks = async (body) => {
    console.log("inside the my tasks", body);
    try {

        const response = await instanceAll.post("/get-my-leads-tasks", body);
        if (response.status === 200 && response.data.isLeadsTasks) {
            return (
                {
                    data: response.data.taskDetails,
                    status: 'success',
                    count: response.data.count
                }
            )
        }
        else {
            return (
                {
                    data: [],
                    status: "failed"
                }
            )
        }
    }
    catch (error) {
        return (
            {
                status: "failed",
                message: error
            }
        )
    }

}

export const myLeadsAppointments = async (body) => {
    console.log("my leads appointments", body);
    try {

        const response = await instanceAll.post("/get-my-appointments", body);
        if (response?.status === 200 && response?.data?.isGetAppointmentDetails) {
            return {
                status: 'success',
                data: response.data.meetingDetails
            }
        }
        else {
            return (
                {
                    status: "failed",
                    data: []
                }
            )
        }

    }
    catch (error) {
        return (
            {
                status: 'failed',
                message: error
            }
        )
    }
}

export const myLeadsAdvancedFilter = async (body) => {
    try {
        const response = await instanceAll.post("/myLeads-advanced-date-filter", body);
        console.log("response--my-leads--", response);
        if (response?.data?.status === 200 && response?.data?.getMyLeadsFilterList) {
            return ({
                status: "success",
                myLeadsData: response?.data.leadsData,
                count: response.data.count,
                message: response?.data.message
            })
        }
        else {
            return ({
                status: "failed",
                message: response?.data.message
            })
        }

    }
    catch (error) {
        return ({
            status: -1,
            message: error
        })
    }
}

export const myTasksAdvancedFilter = async (body) => {
    try {
        const response = await instanceAll.post("/myTask-advanced-date-filter", body);
        console.log("response--my-leads--", response);
        if (response?.data?.status === 200 && response?.data?.getMyLeadsTaskFilterList) {
            return ({
                status: "success",
                leadsTaskData: response?.data.leadsTaskData,
                count: response.data.count,
                message: response?.data.message
            })
        }
        else {
            return ({
                status: "failed",
                message: response?.data.message
            })
        }

    }
    catch (error) {
        return ({
            status: -1,
            message: error
        })
    }
}

export const myAppoinmntAdvancedFilter = async (body) => {
    try {
        const response = await instanceAll.post("/myAppoinment-advanced-date-filter", body);
        console.log("response--my-leads--", response);
        if (response?.data?.status === 200 && response?.data?.getMyLeadsAppoinmentList) {
            return ({
                status: "success",
                leadsAppoinmentData: response?.data.leadsAppoinmentData,
                count: response.data.count
            })
        }
        else {
            return ({
                status: "failed",
                message: response?.data.message
            })
        }

    }
    catch (error) {
        return ({
            status: -1,
            message: error
        })
    }
}

export const getLeadDataById = async (body) => {
    try {
        console.log("inside the get lead data by id", body);
        const response = await instanceAll.post("/get-lead-byid", body);
        if (response.status === 200 && response.data.isGetLeadData) {
            return (
                {
                    status: "success",
                    leadData: response.data.leadData
                })

        }
        else {
            return (
                {
                    status: "failed",
                    message: "No data Found"
                }
            )
        }


    } catch (error) {
        return (
            {
                status: "failed",
                message: error
            }
        )

    }
}


export const getMyLeadActivityList = async (body) => {
    try {
        const response = await instanceAll.post("/get-lead-activitydata-list", body);
        if (response.status === 200 && response.data.isGetActivityData) {
            return (
                {
                    status: "success",
                    data: response.data.activityList,
                    count: response.data.count
                }
            )
        }
        else {

            return (
                {
                    status: "failed",
                    data: []
                }
            )
        }

    } catch (error) {
        return (
            {
                stautus: "failed",
                message: error,

            }
        )

    }
}

export const getActivityStates = async (body) => {
    try {
        console.log("get activity state", body);
        const response = await instanceAll.post("/get-activity-states", body);
        if (response.status === 200 && response.data.isGetActivityStatus) {
            return (
                {
                    status: "success",
                    data: response.data.activityStatesList
                }
            )
        }
        else {
            return (
                {
                    status: "failed",
                    data: []
                }

            )
        }

    } catch (error) {
        return (
            {
                status: "failed",
                data: []
            }

        )

    }


}

export const updateLeadState = async (body) => {
    try {
        console.log("update lead state", body);
        const response = await instanceAll.post("/update-task-state", body);
        if (response.status === 200 && response.data.isUpdateTaskState) {
            return (
                {
                    status: "success",
                    message: "successfully Updated"
                }
            )
        }
        else {
            return (
                {
                    status: "failed",
                    message: "No data Found"
                }
            )
        }

    } catch (error) {
        return (
            {
                status: "failed",
                message: error
            }
        )

    }
}

export const updateAppointmentStateService = async (body) => {
    try {
        console.log("inside the update appointment state service", body);
        const response = await instanceAll.post("/update-appointment-state", body);
        if (response.status === 200 && response.data.isUpdatedAppointmentState) {
            return (
                {
                    status: "success",
                    message: "successfully updated"
                }
            )
        }
        else {
            return (
                {
                    status: "Failed",
                    message: 'Failed/no data found'
                }
            )
        }

    } catch (error) {
        return (
            {
                status: "Failed",

            }
        )

    }
}


export const rescheduleAppointmentService = async (body) => {
    try {
        const response = await instanceAll.post("/reschedule-meeting", body);
        console.log("response", response);
        if (response.status === 200 && response.data.isRescheduled) {
            return ({
                status: "success",
                message: 'Successfully Rescheduled',
                newAppointmentId: response.data.newAppointmentId
            })
        }
        else {
            return (
                {
                    status: "failed",
                    message: " Failed to Rescheduled"
                }
            )
        }

    } catch (error) {
        return (
            {
                status: "failed",
                message: error
            }
        )
    }
}
export const reassignTaskService = async (body) => {
    try {
        const response = await instanceAll.post("/reassign-task", body);
        console.log("response", response);
        if (response.status === 200 && response.data.isRescheduleTask) {
            return ({
                status: "success",
                message: 'Successfully Rescheduled',
                newAppointmentId: response.data.newAppointmentId
            })
        }
        else {
            return (
                {
                    status: "failed",
                    message: " Failed to Rescheduled"
                }
            )
        }

    } catch (error) {
        return (
            {
                status: "failed",
                message: error
            }
        )
    }
}

export const getMyLeadsBySearchService = async (body)=>{
    try {
         const response = await instanceAll.post('/get-myLeads-by-search',body);
         console.log("getMyLeadsBySearch:",response);

         if(response &&  response.status === 200 ){
            return(
                {
                    status:"success",
                    data:response?.data,
                    count: response?.data.count
                }
            )
         }else{
            return(
                {
                    status:"failed",
                    data:response.data
                }
            )
         }

    } catch (error) {
        return (
            {
                status:-1,
                message:error
            }
        )
    }
}

export const getMyTasksBySearchService = async (body)=>{
    try {
         const response = await instanceAll.post('/get-myTask-by-search',body);
         console.log("getMyTasksBySearch:",response);

         if(response &&  response.status === 200 ){
            return(
                {
                    status:"success",
                    data:response?.data,
                    count: response?.data.count
                }
            )
         }else{
            return(
                {
                    status:"failed",
                    data:response.data
                }
            )
         }

    } catch (error) {
        return (
            {
                status:-1,
                message:error
            }
        )
    }
}

export const callLeads = async (body) => {
    try {
        const res = await instanceAll.post("/call-raw-lead", body);
        // console.log("Called the lead: ", res);
        
        if (res.status === 200 && res.data) {
            return (
                {
                    status: "success",
                    message: "Called Lead Successfully",
                    data: res.data
                }
            )
        } else {
            return (
                {
                    status: "failed",
                    message: "Failed to Call Lead"
                }
            )
        }
    } catch (error) {
        return (
            {
                status: "failed",
                message: error
            }
        )
    }
}

export const getAgentPhone = async (body) => {
    try {
        const response = await instanceAll.post("/get-user-cities", body);
        console.log(response,"response")
        if (response) {
            return (
                {
                    status: "success",
                    message: "Successfully retrived the cities",
                    phone:response.data._mobile
                }
            )
        }
        else {
            return (
                {
                    status: "failed",
                    message: "No Data Found"
                }
            )
        }

    } catch (error) {
        return (
            {
                status: "failed",
                message: error
            }
        )
    }
}

export const getCallRecordings = async (body) => {
    try {
        const response = await instanceAll.post("/api/recordings", body);
        console.log(response,"response")
        if (response) {
            return (
                {
                    status: "success",
                    message: "Successfully retrived the recordings",
                    data :response.data
                }
            )
        }
        else {
            return (
                {
                    status: "failed",
                    message: "No call recordings Found"
                }
            )
        }

    } catch (error) {
        return (
            {
                status: "failed",
                message: error
            }
        )
    }
}

export const getCallStatus = async (sid) => {
    try {
        const response = await instanceAll.get('/api/pending-calls');
        console.log("Full response from API: ", response);
        
        const inProgressCalls = response.data?.inProgressCalls; // Safely access nested fields
        console.log("In-progress calls: ", inProgressCalls);
        
        const match = inProgressCalls.includes(sid);
        
        if (response.data?.status === 200) {
            return {
                status: "success",
                message: "Successfully retrieved the in-progress calls.",
                data: inProgressCalls,
                result: match,
            };
        } else {
            return {
                status: "error",
                message: "Response status not 200.",
                result: false,
            };
        }
    } catch (error) {
        console.error("Error fetching in-progress calls: ", error.message);
        return {
            status: "error",
            message: "Failed to fetch in-progress calls.",
            result: false,
        };
    }
};