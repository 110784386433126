import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch, useRouteMatch } from 'react-router-dom';
import '../../Styles/Landing.css';
import '../PartnerExecutive/PeSidebar.css';
import Sidebar from './Sidebar';
import routes from ".."
import { Spin } from 'antd';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'


library.add(fas, far, fab)

//  useRout
function SidebarRoutes() {
  const route = useRouteMatch();
  // console.log("sidebarroutes::",route)
  return (
    <div>
      {/* <Router> */}
      <div className="whAppLayout">
        <Sidebar />
        <div className="whAppContent">
          <Suspense fallback={null}>
            <Switch>
              <Route path="/landing/partnerexecutive/dashboard" component={routes.Dashboard} />
              <Route path="/landing/partnerexecutive/turnkeyPartners" component={routes.TurnkeyPartner} />
              <Route path="/landing/partnerexecutive/skilledWorkers" component={routes.SkilledWorkers} />
              <Route path="/landing/partnerexecutive/architechPartners" component={routes.ArchitechPartners} />
              <Route path="/landing/partnerexecutive/interiorPartners" component={routes.InteriorPartners} />
              {/* <Route path="/landing/partnerexecutive/setupInterviews" component={routes.SetUpInterviews} /> */}
              <Route path="/landing/partnerexecutive/myTrips" component={routes.MyTrips} />
              {/* Routes for Partner Manager  */}
              <Route path="/landing/partnermanager/dashboard" component={routes.PmDashboard} />
              <Route path="/landing/partnermanager/tkpAllUsers" component={routes.allTKPUsers} />
              <Route path="/landing/partnermanager/tkpPendingApprovals" component={routes.TkpPenndingApprovals} exact={true} />
              <Route path="/landing/partnerManager/tkpOnboardingProcess" component={routes.TkpOnboardingProcess} />
              <Route path="/landing/partnermanager/allSkilledWorkers" component={routes.AllSkilledWorkersList} />
              <Route path="/landing/partnerManager/skilledWorkerPending" component={routes.SkilledWorkersPending} exact={true} />
              <Route path="/landing/partnerManager/skilledWorkerOnboardingProcess" component={routes.SkilledWorkersOnboardingProcess} />
              <Route path="/landing/partnermanager/allArchitectPartners" component={routes.AllArchitectPartnersList} />
              <Route path="/landing/partnerManager/architectPartnersPending" component={routes.ArchitectPartnersPending} />
              <Route path="/landing/partnerManager/architectPartnerOnboardingProcess" component={routes.ArchitectPartnerOnboardingProcess} />
              <Route path="/landing/partnermanager/allInteriorPartners" component={routes.AllInteriorPartnersList} />
              <Route path="/landing/partnermanager/interiorPartnersPending" component={routes.InteriorPartnersPending} />
              <Route path="/landing/partnermanager/interiorPartnersOnboardingProcess" component={routes.InteriorPartnersOnboardingProcess} />
              {/* <Route path="/landing/partnermanager/myCalender" component={routes.MyCalender} /> */}
              <Route path="/landing/partnermanager/tkpAppoinment" component={routes.TkpAppoinment} />


              {/* <Route path="/landing/partnermanager/skilledWorkersApprovals" component={SkilledWorkersApprovals} /> */}
              <Route path="/landing/salesexecutive/dashboard" component={routes.SeDashboard} />
              <Route path="/landing/salesexecutive/customers" component={routes.Customers} />
              <Route path="/landing/salesexecutive/projects" component={routes.Projects} />
              <Route path="/landing/salesexecutive/myleads" component={routes.Leads} />
              <Route path="/landing/salesexecutive/mytasks" component={routes.Tasks} />
              <Route path="/landing/salesexecutive/mycalls" component={routes.SalesExecutiveCalls} />
              <Route path="/landing/salesexecutive/myappointments" component={routes.SalesExecutiveAppointments} />
              <Route path="/landing/salesexecutive/genericactivities" component={routes.SalesExecutiveGenericActivites} />

              <Route path="/landing/tkpmenu/dashboard" component={routes.TkpDashboard} />
              <Route path="/landing/tkpmenu/materialOrders" component={routes.MaterialOrder} />
              <Route path="/landing/tkpmenu/requestSkilledWorkers" component={routes.RequestSkilledWorkers} />
              <Route path="/landing/users" component={routes.Users} />
              <Route path="/landing/roles" component={routes.Roles} />

              {/* Added new routes for wehouse users and add wehouse users  */}
              <Route path="/landing/admin/wehouseUsers" component={routes.Wehouse} />
              <Route path="/landing/admin/wehouseUsers" component={routes.WehouseUsersList} />
              <Route path="/landing/admin/addWeHouseUsers" component={routes.AddWeHouseUsers} />
              {/* sales coordinator screens */}
              <Route path="/landing/salescoordinator/dashboard" component={routes.SalesCoordinatorDashboard} />
              <Route path="/landing/salescoordinator/rawleads" component={routes.RawLead} />
              <Route path="/landing/salescoordinator/projects" component={routes.Projects1}/>
              <Route path="/landing/salescoordinator/customers" component={routes.Customers} />

              {/* Project managment officer */}
              <Route path="/landing/pmo/dashboard" component={routes.PMODashboard} />
              <Route path="/landing/pmo/projects" component={routes.PMOProjects} />
              {/* <Route path="/landing/pmo/customers" component={routes.PMOCustomers} /> */}
              {/* project Coordinator */}
              <Route path="/landing/pc/dashboard" component={routes.PCDashboard} />
              <Route path="/landing/pc/projects" component={routes.PCProjects} />
              {/* screen desgin */}
              <Route path="/landing/notYetSet" component={routes.ScreenDesign} />
              {/* customer Screens */}
              <Route path="/landing/customer/projects" component={routes.customerDashboard} />
              <Route path="/landing/customer/buildingProgresss" component={routes.BuildingProgress} />
              <Route path="/landing/customer/drawings" component={routes.Drawings}/>
              <Route path="/landing/customer/paymentProcess" component={routes.PaymentProcess} />
              {/* project manager screens */}
              <Route path="/landing/pm/dashboard" component={routes.PMDashboard} />
              <Route path="/landing/pm/projects" component={routes.PMProjects} />
              {/* <Route path="/landing/pm/customers" component={routes.PMCustomers} /> */}


              {/* Sales Office screens */}
              <Route path="/landing/salesoffice/dashboard" component={routes.SODashboard} />
              <Route path="/landing/salesoffice/rawleads" component={routes.SOLeads} />
            
            </Switch>
          </Suspense>
        </div>
      </div>
      {/* </Router> */}
    </div>
  )
}

export default SidebarRoutes;